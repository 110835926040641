import Api from "./api";

const api = new Api();

export const getApplyPromo = async ({ id, ...params }) => {
  return await api.get(`/payment/apply-promo/${id}/`, params);
};

export const getApplyAffiliate = async ({ id, ...params }) => {
  return await api.get(`/payment/apply-affiliate/${id}/`, params);
};

export const makeSslPayment = async ({ id, ...params }) => {
  return await api.post(`/payment/v2/payment/${id}/`, params);
};

export const makeStripePayment = async ({ id, ...params }) => {
  return await api.post(`/payment/v2/stripe/${id}`, params);
};

export const getPromoImage = async () => {
  return await api.get(`/payment/promo-image/`);
};

export const getSalesVoucher = async ({ id, ...params }) => {
  return await api.get(`/payment/apply-sales-discount/${id}/`, params);
};

export const wheelerCampaign = async (data) => {
  return await api.post("payment/wheeler-campaign/", data);
};

export const getWheelerAvailed = async () => {
  return await api.get("payment/availed-discount/");
};
