import { Link } from "react-router-dom";
import { BiSolidDashboard } from "react-icons/bi";
import MenuItems from "../DropMenuItems/MenuItems";
import useSendEvent from "hooks/useSendEvent";

export default function LeftSideNav({ changeColor }) {
  const { sendEvent } = useSendEvent();

  const menuItems = [
    {
      title: "More",

      submenu: [
        {
          title: "About Us",
          url: "/about",
        },
        {
          title: "Career",
          url: "/career",
        },
        {
          title: "Hire",
          url: "/hiringpage",
        },
        {
          title: "Affiliate",
          url: "/affiliation",
        },
        {
          title: "Support Session",
          url: "/support-session",
        },
        {
          title: "Resume Builder",
          url: "/js-dashboard/resume",
        },
        {
          title: "Blogs",
          url: "/blogs",
        },
      ],
    },
  ];

  const handleSendEvent = (value) => {
    sendEvent(value, "home");
  };

  return (
    <div
      className={`text-sm ${
        changeColor === true
          ? "text-black  flex gap-3 items-center ml-5"
          : "text-white  flex gap-3 items-center ml-5"
      } `}
    >
      <div className="flex items-center gap-3 border-r-2 border-solid pr-3">
        <BiSolidDashboard
          className={changeColor ? "text-black text-xl" : "text-white text-xl"}
        />

        <Link to="/allcourses" onClick={() => handleSendEvent("All Courses")}>
          <h3>All Courses</h3>
        </Link>
      </div>
      <Link to="/allCareerPath" onClick={() => handleSendEvent("Career Path")}>
        <h3>Career Path</h3>
      </Link>

      <Link to="/business" onClick={() => handleSendEvent("Business")}>
        <h3>Business</h3>
      </Link>

      <div>
        <ul className="menus">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <MenuItems items={menu} key={index} depthLevel={depthLevel} />
            );
          })}
        </ul>
      </div>
    </div>
  );
}
