import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import { RxCross1 } from "react-icons/rx";
import { useMutation, useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CryptoJS from "crypto-js";

import api from "api";
import useAuthToken from "hooks/useAuthToken";
import SpinWheel from "components/SpinTheWheel/SpinWheel";
import success from "assets/payment/check-mark.png";

const WheelModal = ({ open, setOpen }) => {
  const { authToken } = useAuthToken();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [winningValue, setWiningValue] = useState(null);
  const debounceTimeout = useRef(null);

  const { data: courses, isLoading } = useQuery({
    queryKey: ["all-course", searchTerm],
    queryFn: () =>
      api.course.getAllCourse({
        isAuthenticate: !!authToken || !!localStorage.getItem("access"),
        search: searchTerm,
        limit: 20,
      }),
    refetchOnWindowFocus: false,
  });

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const {
    mutate,
    isLoading: isSending,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: api.payment.wheelerCampaign,
  });

  const handleSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog
      onClose={(event, reason) => handleClose(event, reason)}
      aria-labelledby="wheel-modal"
      open={open}
      PaperProps={{
        sx: {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          maxWidth: "520px",
          width: "100%",
          backgroundColor: "#1c1b3b",
        },
      }}
      maxWidth="sm"
    >
      <div className="relative overflow-hidden p-0 md:p-[24px]">
        {!isSuccess && (
          <RxCross1
            className="absolute top-3 right-3 text-xl text-white cursor-pointer "
            onClick={() => setOpen(false)}
          />
        )}

        {isSuccess ? (
          <div className="flex flex-col items-center px-6 py-8 rounded-lg">
            <img src={success} alt="Success" className="w-20 h-20 mb-4" />
            <h2 className="text-2xl font-semibold text-white mb-2">
              🎉 অভিনন্দন!
            </h2>
            <p className="text-base text-white text-center leading-6">
              আপনার ইমেইল এ একটি লিংক পাঠানো হয়েছে। অনুগ্রহ করে আপনার ইনবক্স চেক
              করুন এবং পরবর্তী নির্দেশিকা অনুসরণ করুন।
            </p>
            <button
              onClick={() => setOpen(false)}
              className="mt-6 px-6 py-2 text-sm font-medium text-white bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Close
            </button>
          </div>
        ) : (
          <div>
            {!winningValue && (
              <div className="flex flex-col items-center mb-6  pt-[25px] px-[20px] md:pt-[0]">
                <h2 className="text-center text-base sm:text-[1.3rem] text-white font-bold pt-4 mb-2">
                  স্পিন করুন, আর পেয়ে যান বিশেষ ডিসকাউন্ট!
                </h2>

                <SpinWheel setWiningValue={setWiningValue} />
              </div>
            )}

            {winningValue && (
              <>
                <div className="text-center mb-0 md:mb-6 p-[20px] md:p-0">
                  <h3 className="text-2xl text-white font-medium mb-3">
                    Congratulations! 🎉
                  </h3>
                  <p className="text-white">
                    আপনি{" "}
                    <span className="font-bold text-white">{winningValue}</span>{" "}
                    ডিসকাউন্ট কুপন পেয়েছেন। ডিসকাউন্ট পাওয়ার জন্যে পছন্দের কোর্স
                    বা ক্যারিয়ার পাথ সিলেক্ট করে submit বাটনে ক্লিক করুন।
                  </p>
                </div>

                <div className="mb-6 p-[20px] md:p-0">
                  <Autocomplete
                    options={courses?.results || []}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(event, value) => {
                      setSelectedCourse(value?.id || null);
                      if (!value) {
                        setSearchTerm("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="কোর্স সিলেক্ট অথবা অনুসন্ধান করুন"
                        variant="outlined"
                        fullWidth
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#FFF",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "#FFF",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#FFF",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1976d2",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FFF",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#FFF",
                          },
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    loading={isLoading}
                    noOptionsText="No courses found"
                    sx={{
                      "& .MuiAutocomplete-option": {
                        color: "#FFF",
                      },
                      "& .MuiAutocomplete-option[aria-selected='true']": {
                        backgroundColor: "#1976d2",
                        color: "#FFF!important",
                      },
                      "& .MuiAutocomplete-option:hover": {
                        backgroundColor: "#005bb5",
                        color: "#FFF",
                      },
                      "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                        color: "#FFF",
                      },
                    }}
                  />

                  {error && (
                    <span className="text-red-500 my-1 text-sm font-[600]">
                      {error.message}
                    </span>
                  )}

                  <div className="flex justify-center mt-4">
                    <button
                      disabled={!selectedCourse || isSending}
                      onClick={() => {
                        const encrypted = CryptoJS.AES.encrypt(
                          winningValue.replace("%", ""),
                          process.env.REACT_APP_WHEELER_SALT
                        );

                        mutate({
                          course: selectedCourse,
                          discount: encrypted.toString(),
                        });
                      }}
                      className={`w-[200px] rounded px-5 py-2.5 overflow-hidden group relative text-black font-semibold transition-all ease-out duration-300 ${
                        !selectedCourse
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-[#9FE2BF] hover:bg-gradient-to-r "
                      }`}
                    >
                      <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                      <span className="relative flex items-center justify-center">
                        {isSending ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default WheelModal;
