import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuthToken from "hooks/useAuthToken";

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const { authToken } = useAuthToken();

  if (!authToken || authToken === "undefined") {
    const loginPath = {
      pathname: "/login",
      search: location.search,
    };

    return <Navigate to={loginPath} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoutes;
