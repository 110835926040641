import Api from "./api";

const api = new Api();

export const fetchGenres = async () => {
  return await api.get("/course/bestgenres/");
};

export const getAllCourse = async ({ isAuthenticate, ...params }) => {
  const allCourses = await api.get("/course/courses/", params);

  if (!isAuthenticate) return allCourses;

  const enrollData = await api.get(`/course/enrolled/`);

  allCourses?.results?.forEach((course) => {
    course.isEnrolled = enrollData?.some(
      (enroll) => course.id === enroll.course
    );
  });

  return allCourses;
};

export const getFeaturedCourse = async () => {
  const featureCourse = await api.get("course/featured-course");

  return featureCourse;
};

export const getCareerPath = async (id) => {
  return await api.get(`/course/career_path/${id}/`);
};

export const getCourseDetails = async (id) => {
  return await api.get(`/course/course_detail/${id}/`);
};

export const getCheckEnrollment = async (id) => {
  return await api.get(`/course/check-enrollment/${id}/`);
};

export const getWhatsappNumber = async (id) => {
  return await api.get(`/marketing/whatsapp-number/${id}/`);
};

export const getListCareerPath = async ({ isAuthenticate }) => {
  const careerPathCourse = await api.get(`/course/career-path-list/`);

  if (!isAuthenticate) return careerPathCourse;

  const enrollData = await api.get(`/course/enrolled/`);

  careerPathCourse?.forEach((course) => {
    course.isEnrolled = enrollData?.some(
      (enroll) => course.course?.id === enroll.course
    );
  });

  return careerPathCourse;
};

export const getById = async (id) => {
  return await api.get(`/course/courses/${id}/`);
};

export const addConsultation = async (data) => {
  return await api.post("course/create-consultation/", data);
};

export const sendCouponLink = async (data) => {
  return await api.post("course/create-consultation/", data);
};

export const masterclass = async (data) => {
  return await api.post("course/masterclass-consultation/", data);
};
